var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('DataTable', {
    ref: "dataTable",
    attrs: {
      "options": _vm.options
    }
  }), _c('FileUploader', {
    ref: "uploader",
    attrs: {
      "options": _vm.fileUploaderOptions
    },
    on: {
      "close": function close($event) {
        return _vm.$refs.dataTable.getData();
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }