<template>
  <div>
    <DataTable ref="dataTable" :options="options" />
    <FileUploader ref="uploader" :options="fileUploaderOptions" @close="$refs.dataTable.getData()" />
  </div>
</template>

<script>
import FileUploader from '@tutti/components/FileUploader';

export default {
  components: {
    FileUploader,
  },

  data() {
    return {
      options: {
        addNew: this.addNew,
        headers: [
          { text: 'Preview', value: 'url', component: { name: 'dt-img' } },
          { text: 'URL', value: 'url', component: { name: 'dt-link' }, filter: true },
          {
            text: 'Name',
            value: 'name',
            capitalize: true,
            component: { name: 'dtView', props: { name: 'file', permission: 'file' } },
          },
          { text: 'Size', value: 'size', component: { name: 'dt-size' } },
          { text: 'Type', value: 'type', capitalize: true },
        ],
        actions: [
          {
            text: 'Delete',
            body: { isDeleted: true },
          },
        ],
        filter: {
          from: 'cms',
        },
      },
      fileUploaderOptions: {
        headers: { authorization: this.$cookies.get(process.env.VUE_APP_AUTH_TOKEN_KEY), partners: false, admin: true },
      },
    };
  },

  methods: {
    addNew() {
      this.$refs.uploader.openModal();
    },
  },
};
</script>
